<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">เปลี่ยนรหัสผ่าน</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.OldPassword"
                  label="รหัสผ่านเก่า"
                  type="password"
                  dense
                  required           
                  ref="OldPassword"
                  @keydown.enter="NextFocus('Password')"       
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.Password"
                  label="รหัสผ่านใหม่"
                  type="password"
                  dense
                  required
                  ref="Password"
                  @keydown.enter="NextFocus('ConfirmPassword')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.ConfirmPassword"
                  label="ยืนยันรหัสผ่านใหม่"
                  type="password"
                  dense
                  required
                  ref="ConfirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel">
            ยกเลิก
          </v-btn>
          <v-btn class="primary" @click="submit">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["modal", "item"],
  data() {
    return {
      dialog: false,
      itemEdit: {},
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["actChangePassword"]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    submit() {
      if (this.itemEdit.Password == "") {
        this.Warning("กรุณาระบุรหัสผ่าน");
        return;
      }
      if (this.itemEdit.Password != this.itemEdit.ConfirmPassword) {
        this.Warning("รหัสผ่านไม่ตรงกัน");
        return;
      }
      this.actChangePassword(this.itemEdit).then(({ status, data }) => {
        if (status == 200) {
          if (data.success) {
            this.Info("เปลี่ยนรหัสผ่านเรียบร้อย");
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        } else {
          this.Error(data.message);
        }
      });
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
  },
};
</script>

<style></style>
