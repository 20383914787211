<template>
  <div id="login">
    <v-app>
      <v-container fill-height fluid class="accent">
        <v-row align="center" justify="center" class="mb-16">
          <v-col cols="12" sm="8" md="4">
            <v-card color="primary" class="elevation-12">
              <v-card-title class="text-center justify-center py-3">
                <h2 class="font-weight-bold white--text">Back Office</h2>
              </v-card-title>

              <v-tabs
                v-model="tab"
                background-color="white"
                color="primary"
                grow
              >
                <v-tab class="subtitle-1"> เข้าสู่ระบบ </v-tab>
                <!-- <v-tab class="subtitle-1">
                  ลงทะเบียน
                </v-tab> -->
                <v-tab-item>
                  <v-card color="" flat>
                    <v-card-text>
                      <v-form>
                        <v-text-field
                          label="ชื่อผู้ใช้"
                          v-model="userLogin.UserId"
                          prepend-icon="mdi-account"
                          type="text"
                          :rules="idRules"
                          ref="UserId"
                          @keydown.enter="NextFocus('Password')"
                        ></v-text-field>
                        <v-text-field
                          label="รหัสผ่าน"
                          v-model="userLogin.Password"
                          prepend-icon="mdi-lock"
                          type="password"
                          :rules="passwordRules"
                          @keydown.enter="login"
                          ref="Password"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="login" color="primary">เข้าระบบ</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
                <!-- <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form>
                        <v-text-field
                          label="ชื่อผู้ใช้"
                          v-model="userLogin.UserId"
                          prepend-icon="mdi-account"
                          type="text"
                          :rules="idRules"
                        ></v-text-field>
                        <v-text-field
                          label="รหัสผ่าน"
                          v-model="userLogin.Password"
                          prepend-icon="mdi-lock"
                          type="password"
                          :rules="passwordRules"
                        ></v-text-field>
                        <v-text-field
                          label="ยืนยันรหัสผ่าน"
                          v-model="userLogin.ConfirmPassword"
                          prepend-icon="mdi-lock"
                          type="password"
                          :rules="passwordRules"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="register">ลงทะเบียน</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item> -->
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
        <v-footer absolute padless class="secondary white--text">
          <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} — <strong>Sanupsanoon</strong>
          </v-col>
        </v-footer>
      </v-container>
      <dialogChangePassword
        :modal="showDialogChangePassword"
        :item="itemDialogChangePassword"
        @close="closeDialogChangePassword"
      ></dialogChangePassword>
    </v-app>
  </div>
</template>

<script>
import dialogChangePassword from "./settings/user/settingUser/DialogChangePassword";
import { mapActions, mapState } from "vuex";
import { newUser } from "@/store/modules/auth/state";
export default {
  name: "Login",
  components: {
    dialogChangePassword,
  },
  data() {
    return {
      tab: null,
      userLogin: newUser(),
      idRules: [(v) => !!v || "ระบุชื่อผู้ใช้"],
      passwordRules: [(v) => !!v || "ระบุรหัสผ่าน"],
      showDialogChangePassword: false,
      itemDialogChangePassword: {},
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["actLogin", "actRegister"]),
    ...mapActions("settings", ["actParameterGets"]),

    login() {
      // this.userName = "สมชาย คล้ายคลึง"
      // let loginUser = {
      //   UserId: "12346",
      //   userName: "สมชาย คล้ายคลึง",
      // };
      // let user = this.user;
      // if (user.userId == "" || user.password == "") {
      //   this.Warning("กรุณาใส่ชื่อผู้ใช้และรหัสผ่าน");
      //   return;
      // }
      if (this.userLogin.UserId == "") {
        this.Warning("กรุณาระบุชื่อผู้ใช้");
        return;
      }
      this.actLogin(this.userLogin).then(({ status, data }) => {
        if (status == 200) {
          if (data.success) {
            this.actParameterGets().then(({ status }) => {
              if (status == 200) {
                this.$router.push("/").catch(() => {});
              }
            });
          } else {
            if (data.message == "ไม่มีรหัสผ่าน") {
              this.itemDialogChangePassword = newUser();
              this.itemDialogChangePassword.UserId = this.userLogin.UserId;
              this.showDialogChangePassword = true;
            } else {
              this.Error(data.message);
            }
          }
        } else {
          this.Error(data.message);
        }
      });
    },
    closeDialogChangePassword(value) {
      this.showDialogChangePassword = value;
    },
    // register() {
    //   if (this.userLogin.Password != this.userLogin.ConfirmPassword) {
    //     this.Warning("รหัสผ่านไม่ตรงกัน");
    //     return;
    //   }
    //   this.actRegister(this.userLogin).then(({ status, data }) => {
    //     if (status == 200 && data.success) {
    //       this.Info("ลงทะเบียนเรียบร้อย");
    //     } else {
    //       this.Error(data.message);
    //     }
    //   });
    // },
  },
};
</script>

<style></style>
